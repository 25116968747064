<template>
  <div class="d-flex px-4 py-4 flex-wrap justify-center" style="gap: 26px">
    <v-card>
      <v-card-title>
        <p
          class="pb-0 mb-0 font-weight-bold text-uppercase subtitle-1"
          style="word-break: break-word"
        >
          <v-icon class="mr-1" style="vertical-align: middle"
            >mdi-clipboard-check</v-icon
          >
          Monto adjudicado
        </p>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div class="text-center">
          <span class="text-h5 font-weight-bold">
            ${{ montos.total_adjudicado }}</span
          >
        </div>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title>
        <p
          class="pb-0 mb-0 font-weight-bold text-uppercase subtitle-1"
          style="word-break: break-word"
        >
          <v-icon class="mr-1" style="vertical-align: middle"
            >mdi-clipboard-edit-outline</v-icon
          >
          Monto de modificativa
        </p>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div class="text-center">
          <span class="text-h5 font-weight-bold"
            >${{ montos.monto_modificativa }}</span
          >
        </div>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title>
        <p
          class="pb-0 mb-0 font-weight-bold text-uppercase subtitle-1"
          style="word-break: break-word"
        >
          <v-icon class="mr-1" style="vertical-align: middle"
            >mdi-check-decagram</v-icon
          >
          Monto ejecutado
        </p>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div class="text-center">
          <span class="text-h5 font-weight-bold"
            >${{ montos.monto_cancelado }}</span
          >
        </div>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title>
        <p
          class="pb-0 mb-0 font-weight-bold text-uppercase subtitle-1"
          style="word-break: break-word"
        >
          <v-icon class="mr-1" style="vertical-align: middle"
            >mdi-cash</v-icon
          >
          Monto disponible
        </p>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div class="text-center">
          <span class="text-h5 font-weight-bold"
            >${{ montos.monto_total }}</span
          >
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      colors: ["red", "green", "orange", "blue", "purple"],
      icons: [
        "mdi-cash-minus",
        "mdi-cash-plus",
        "mdi-cash-clock",
        "mdi-cash",
        "mdi-currency-usd",
      ],
    };
  },
  computed: {
    ...mapState("seguimientoOrden", ["montos"]),
  },
  methods: {
    ...mapMutations("seguimientoOrden", ["setMontos"]),
    async getMetricasPagos() {
      const { status, data } =
        await this.services.ContratoService.getMontosPagos(
          this.$route.params.id_orden
        );
      if (status === 200) {
        this.setMontos(data);
        this.$emit('montoModificativa', this.montos.monto_modificativa);
      }
    },
    formatKey(nombre) {
      return nombre.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase());
    },
    getColor(nombre) {
      const index =
        Object.keys(this.montos).indexOf(nombre) % this.colors.length;
      return this.colors[index];
    },
    getIcon(nombre) {
      const index =
        Object.keys(this.montos).indexOf(nombre) % this.icons.length;
      return this.icons[index];
    },
  },
  created() {
    this.getMetricasPagos();
  },
};
</script>
